import { createContext, useContext, useEffect, useState, ReactNode } from "react";
import {useIntl} from 'react-intl'

const TitleContext = createContext<{
    setTitle: (title: string) => void;
}>({
    setTitle: () => {},
});

export const TitleProvider = ({ children }: { children: ReactNode }) => {
    const [title, setTitle] = useState("");
    const intl = useIntl()

    useEffect(() => {
        document.title = intl.formatMessage({id: 'PAGE_TITLE.PREFIX'}) + title;
    }, [title]);

    return (
        <TitleContext.Provider value={{ setTitle }}>
            {children}
        </TitleContext.Provider>
    );
};

export const useTitle = () => useContext(TitleContext);