import React, {useCallback, useEffect, useState} from 'react'
import {IBudgetForecast, LIST_FILTERS} from '../models/BudgetForecast'
import {useDispatch, useSelector} from 'react-redux'
import {actions, selectors} from '../redux/BudgetForecastsRedux'
import BudgetForecastListItem from './BudgetForecastListItem'
import {useIntl} from 'react-intl'
import PageItemsSelect from '../../common/PageItemsSelect/PageItemsSelect'
import SearchInput from '../../common/SearchInput/SearchInput'
import {Button} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import Spinner from '../../common/Spinner/Spinner'
import Pagination from '../../common/Pagination/Pagination'
import ErrorView from '../../common/ErrorView/ErrorView'
import {CreateBudgetForecastModal} from './CreateBudgetForecastModal'
import {ITEM_PER_PAGE} from '../../common/PageItemsSelect/models/PerPage'
import {useTitle} from "../../../TitleContext";

const BudgetForecastsList: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const searchParam = useSelector(selectors.getBudgetForecasts)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pages, setPages] = useState<number>()
  const [limitPerPage, setLimitPerPage] = useState<number>(10)
  const [listFilter, setListFilter] = useState<string>(LIST_FILTERS[0].value)
  const [budgetForecastsPerPage, setBudgetForecastsPerPage] = useState<IBudgetForecast[]>()
  const [searchValue, setSearchValue] = useState<string>('')

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)

  const handleCloseCreate = useCallback(() => {
    setShowCreateModal(false)
  }, [])

  const handleShowCreate = useCallback(() => {
    setShowCreateModal(true)
  }, [])

  const { setTitle } = useTitle()
  setTitle(intl.formatMessage({id: 'PAGE_TITLE.BUDGET_FORECASTS'}))

  useEffect(() => {
    setPages(searchParam.totalPages)
    setBudgetForecastsPerPage(searchParam.getBudgetForecasts)
    setLoading(searchParam.getBudgetForecastsLoading)
    setError(searchParam.getBudgetForecastsError)
  }, [searchParam])

  useEffect(() => {
    dispatch(actions.changeCurrentPage(currentPage))
    dispatch(actions.changeLimitPerPage(Number(limitPerPage)))
    dispatch(actions.changeFilter(listFilter))
    dispatch(actions.changeSearchText(searchValue))
    dispatch(actions.requestBudgetForecasts())
  }, [currentPage, limitPerPage, listFilter, searchValue, dispatch])

  const onSelectHandle = useCallback((event) => {
    setLimitPerPage(event.target.value)
    setCurrentPage(1)
  }, [])

  const onFilterHandle = useCallback((event) => {
    setListFilter(event.target.value)
    setCurrentPage(1)
  }, [])

  const onSearchHandle = useCallback((event) => {
    setSearchValue(event.target.value)
    setCurrentPage(1)
  }, [])

  return !error ? (
    <>
      <div
        className={
          loading ? 'card mb-5 mb-xl-8 overlay overlay-block rounded' : 'card mb-5 mb-xl-8'
        }
      >
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              {intl.formatMessage({id: 'BUDGET_FORECASTS.LIST'})}
            </span>
          </h3>
          <div className='d-flex flex-stack'>
            <div className='d-flex align-items-center position-relative me-4'>
              <PageItemsSelect
                defaultValue={listFilter}
                handle={onFilterHandle}
                options={LIST_FILTERS}
              />
            </div>
            <div className='d-flex align-items-center position-relative me-4'>
              <SearchInput handle={onSearchHandle} />
            </div>
            <div className='card-toolbar'>
              <Button
                className='btn btn-sm btn-light-primary'
                variant='light'
                onClick={handleShowCreate}
              >
                <KTSVG
                  path='/media/icons/duotone/Communication/Add-user.svg'
                  className='svg-icon-3'
                />
                {intl.formatMessage({id: 'ADVERTISING_CHANNEL.NEW'})}
              </Button>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {!loading ? (
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-200px'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.INDUSTRY'})}
                    </th>
                    <th className='min-w-100px'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CHANNEL'})}
                    </th>
                    <th className='min-w-100px'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CPC'})}
                    </th>
                    <th className='min-w-100px'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CTR'})}
                    </th>
                    <th className='min-w-100px'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CONVERSION_RATE'})}
                    </th>
                    <th className='min-w-100px'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CONVERSION_VALUE'})}
                    </th>
                    <th className='min-w-100px'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CONVERSION_NUMBER'})}
                    </th>
                    <th className='min-w-100px'>
                      {intl.formatMessage({id: 'BUDGET_FORECASTS.CART_VALUE'})}
                    </th>
                    <th className='min-w-100px text-end'>{intl.formatMessage({id: 'ACTIONS'})}</th>
                  </tr>
                </thead>
                <tbody>
                  {budgetForecastsPerPage &&
                    budgetForecastsPerPage.map((item, index) => {
                      return <BudgetForecastListItem key={index} budgetForecast={item} />
                    })}
                </tbody>
              </table>
            ) : (
              <Spinner />
            )}
          </div>
          <div className='d-flex flex-stack flex-wrap pt-10'>
            <div className='fs-6'>
              <PageItemsSelect
                defaultValue={limitPerPage}
                handle={onSelectHandle}
                options={ITEM_PER_PAGE}
              />
            </div>
            <Pagination {...{pages, currentPage, setCurrentPage}} />
          </div>
        </div>
      </div>
      <CreateBudgetForecastModal {...{showCreateModal, handleCloseCreate}} />
    </>
  ) : (
    <ErrorView />
  )
}

export default BudgetForecastsList
