import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import Attributes from './attributes/Attributes'
import Prompts from './prompts/Prompts'
import FeedgenHeader from './FeedgenHeader'
import GoogleCategories from "./googleCategory/GoogleCategories"
import { useIntl } from "react-intl"

const FeedgenPage: React.FC = () => {
    const intl = useIntl()
    return (
        <div className='card'>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'FEED_DIAGNOSIS'})}</PageTitle>
            <div className='card-body'>
                <FeedgenHeader />
                <div className='content-area'>
                    <Switch>
                        <Route exact path='/feedgen' component={Attributes} />
                        <Route exact path='/feedgen/prompts' component={Prompts} />
                        <Route exact path='/feedgen/required-positions' component={GoogleCategories} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}

export default FeedgenPage
