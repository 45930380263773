import React, { useCallback, useContext, useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import AddEmailTemplateModal from './AddEmailTemplateModal'
import EditEmailTemplateModal from './EditEmailTemplateModal'
import EmailTemplateList from './EmailTemplateList'
import { fetchEmailTemplates, deleteEmailTemplate } from '../redux/EmailTemplateCRUD'
import { AlertContext } from '../../../../_metronic/layout/core/AlertContext'
import Pagination from '../../common/Pagination/Pagination'
import { EMAIL_TEMPLATE_LIST_FILTERS, IEmailTemplate } from '../models/EmailTemplate'
import PageItemsSelect from '../../common/PageItemsSelect/PageItemsSelect'
import {useTitle} from "../../../TitleContext";

const EmailTemplates: React.FC = () => {
    const intl = useIntl()
    const { showAlert } = useContext(AlertContext)
    const [emailTemplates, setEmailTemplates] = useState<IEmailTemplate[]>([])
    const [displayedTemplates, setDisplayedTemplates] = useState<IEmailTemplate[]>([])
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [listFilter, setListFilter] = useState<string>(EMAIL_TEMPLATE_LIST_FILTERS[0].value)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const itemsPerPage = 10
    const [totalPages, setTotalPages] = useState<number>(1)

    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [selectedTemplate, setSelectedTemplate] = useState<IEmailTemplate | null>(null)

    const loadEmailTemplates = useCallback(async () => {
        try {
            const filterParams = `${listFilter}`
            const response = await fetchEmailTemplates(1, 1000, filterParams)
            console.log('Received data:', response)
            const templates = Array.isArray(response) ? response : []
            setEmailTemplates(templates)
        } catch (error) {
            console.error(error)
            showAlert(
                [[intl.formatMessage({ id: 'LOADING.EMAILS.ERROR' }) || 'An error occurred']],
                'danger',
                true
            )
        }
    }, [listFilter, intl, showAlert])

    const { setTitle } = useTitle()
    setTitle(intl.formatMessage({id: 'PAGE_TITLE.EMAIL_TEMPLATES'}))

    useEffect(() => {
        loadEmailTemplates()
    }, [loadEmailTemplates])

    useEffect(() => {
        const filteredTemplates = emailTemplates.filter((template) => {
            const code = template.code.toLowerCase()
            const createdAt = template.created_at ? new Date(template.created_at).toLocaleString().toLowerCase() : ''
            const query = searchQuery.toLowerCase()

            const codeMatch = code.includes(query)
            const createdAtMatch = createdAt.includes(query)

            const subjectMatch = template.translations.some((translation) =>
                translation.subject.toLowerCase().includes(query)
            )

            const bodyMatch = template.translations.some((translation) =>
                translation.body.toLowerCase().includes(query)
            )

            return codeMatch || createdAtMatch || subjectMatch || bodyMatch
        })

        const totalItems = filteredTemplates.length
        const calculatedTotalPages = Math.max(Math.ceil(totalItems / itemsPerPage), 1)
        setTotalPages(calculatedTotalPages)

        if (currentPage > calculatedTotalPages) {
            setCurrentPage(1)
        }

        const startIndex = (currentPage - 1) * itemsPerPage
        const endIndex = startIndex + itemsPerPage
        const paginatedTemplates = filteredTemplates.slice(startIndex, endIndex)
        setDisplayedTemplates(paginatedTemplates)
    }, [emailTemplates, searchQuery, currentPage, itemsPerPage])

    const handleShowCreate = useCallback(() => {
        setShowCreateModal(true)
    }, [])

    const handleCloseCreate = useCallback(() => {
        setShowCreateModal(false)
    }, [])

    const handleShowEdit = useCallback((template: IEmailTemplate) => {
        setSelectedTemplate(template)
        setShowEditModal(true)
    }, [])

    const handleCloseEdit = useCallback(() => {
        setShowEditModal(false)
        setSelectedTemplate(null)
    }, [])

    const handleDeleteTemplate = async (id: number) => {
        try {
            await deleteEmailTemplate(id)
            showAlert(
                [[intl.formatMessage({ id: 'EMAIL_TEMPLATE.DELETE.SUCCESS' }) || 'Deleted successfully']],
                'success',
                true,
                'SUCCESS'
            )
            loadEmailTemplates()
        } catch (error) {
            showAlert([[intl.formatMessage({ id: 'EMAIL.DELETE.FAIL' }) || 'Delete failed']], 'danger', true)
        }
    }

    const handleFilterChange = useCallback(
        (event: React.ChangeEvent<Element>) => {
            const target = event.target as HTMLSelectElement
            const value = target.value
            setListFilter(value)
            setCurrentPage(1)
        },
        []
    )

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value)
        setCurrentPage(1)
    }

    return (
        <>
            <div className='card mb-5 mb-xl-8'>
                <div className='card-body mt-5'>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-between align-items-center'>
                            <h3 className='mb-0'>
                                {intl.formatMessage({ id: 'EMAIL_TEMPLATES.LIST' })}
                            </h3>
                            <div className='d-flex gap-2 align-items-center'>
                                <PageItemsSelect
                                    defaultValue={listFilter}
                                    handle={handleFilterChange}
                                    options={EMAIL_TEMPLATE_LIST_FILTERS}
                                />
                                <input
                                    type='text'
                                    placeholder={intl.formatMessage({ id: 'SEARCH' })}
                                    className='form-control form-control-sm w-auto'
                                    style={{ minWidth: '150px', minHeight: '40px' }}
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                                <Button
                                    className='btn btn-sm btn-light-primary text-nowrap'
                                    onClick={handleShowCreate}
                                >
                                    {intl.formatMessage({ id: 'ADD_EMAIL_TEMPLATE' })}
                                </Button>
                            </div>
                        </div>
                    </div>

                    <EmailTemplateList
                        emailTemplates={displayedTemplates}
                        onEdit={handleShowEdit}
                        onDelete={handleDeleteTemplate}
                    />

                    <Pagination
                        pages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            </div>

            <AddEmailTemplateModal
                show={showCreateModal}
                handleClose={handleCloseCreate}
                loadEmailTemplates={loadEmailTemplates}
            />

            {selectedTemplate && (
                <EditEmailTemplateModal
                    show={showEditModal}
                    handleClose={handleCloseEdit}
                    template={selectedTemplate}
                    loadEmailTemplates={loadEmailTemplates}
                />
            )}
        </>
    )
}

export default EmailTemplates
