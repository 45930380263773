import React from 'react'
import { Link, useLocation, withRouter } from 'react-router-dom'
import { useIntl } from "react-intl"
import {useTitle} from "../../../TitleContext";

const FeedgenHeader: React.FC = () => {
    const location = useLocation()
    const intl = useIntl()

    const { setTitle } = useTitle()
    setTitle(intl.formatMessage({id: 'PAGE_TITLE.FEEDGEN'}))

    return (
        <div className='card'>
            <div className='card-body pb-0'>
                <div className='d-flex overflow-auto'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/feedgen` ? 'active' : '')
                                }
                                to={`/feedgen`}
                            >
                                {intl.formatMessage({ id: 'ATTRIBUTES' })}
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/feedgen/prompts` ? 'active' : '')
                                }
                                to={`/feedgen/prompts`}
                            >
                                {intl.formatMessage({ id: 'PROMPTS' })}
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/feedgen/required-positions` ? 'active' : '')
                                }
                                to={`/feedgen/required-positions`}
                            >
                                {intl.formatMessage({ id: 'REQUIRED_POSITIONS' })}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default withRouter(FeedgenHeader)
