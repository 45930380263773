import {ActionWithDefinePayload, RootState} from '../../../../setup'
import {call, put, takeLatest} from 'redux-saga/effects'
import {IPriceReport} from "../models/PriceReport"
import {getPriceReportList, getPriceReportPdf} from "./PriceReportsCRUD"
import {AnyAction} from "redux";
import {AxiosResponse} from "axios";

export const SET_CLIENT = 'SET_CLIENT'
export const PRICE_REPORT_REQUEST = 'PRICE_REPORT_REQUEST'
export const PRICE_REPORT_REQUEST_SUCCESS = 'PRICE_REPORT_REQUEST_SUCCESS'
export const PRICE_REPORT_REQUEST_FAIL = 'PRICE_REPORT_REQUEST_FAIL'

export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT'

export const PRICE_REPORT_PDF_REQUEST = 'PRICE_REPORT_PDF_REQUEST'
export const PRICE_REPORT_PDF_REQUEST_SUCCESS = 'PRICE_REPORT_PDF_REQUEST_SUCCESS'
export const PRICE_REPORT_PDF_REQUEST_FAIL = 'PRICE_REPORT_PDF_REQUEST_FAIL'

export interface IPriceReportState {
    getPriceReports?: IPriceReport[]
    getPriceReportsLoading: boolean
    getPriceReportsError: boolean
    downloadPdfLoading: string | null
    downloadPdfError: boolean
    client: string
    searchText?: string
}

const initialPriceReportState: IPriceReportState = {
    getPriceReports: [],
    getPriceReportsLoading: false,
    getPriceReportsError: false,
    downloadPdfLoading: null,
    downloadPdfError: false,
    client: '',
    searchText: undefined,
}

export const reducer = (
    state: IPriceReportState = initialPriceReportState,
    action: AnyAction
) => {
    switch (action.type) {
        case PRICE_REPORT_REQUEST:
            return {...state, getPriceReportsLoading: true}
        case PRICE_REPORT_REQUEST_SUCCESS:
            const priceReports = action.payload?.getPriceReports
            return {
                ...state,
                getPriceReports: Array.isArray(priceReports) ? priceReports : [],
                getPriceReportsLoading: false,
                getPriceReportsError: false,
            }
        case PRICE_REPORT_REQUEST_FAIL:
            return {...state, getPriceReportsError: true, getPriceReportsLoading: false}
        case PRICE_REPORT_PDF_REQUEST:
            return {
                ...state,
                downloadPdfLoading: action.payload.reportId,
                downloadPdfError: false,
            }
        case PRICE_REPORT_PDF_REQUEST_SUCCESS:
            return {
                ...state,
                downloadPdfLoading: null,
                downloadPdfError: false,
            }
        case PRICE_REPORT_PDF_REQUEST_FAIL:
            return {
                ...state,
                downloadPdfLoading: null,
                downloadPdfError: true,
            }
        case CHANGE_SEARCH_TEXT:
            const searchText = action.payload?.searchText
            return {...state, searchText}
        default:
            return state
    }
}

export const actions = {
    requestPriceReports: (clientId: string) => ({
        type: PRICE_REPORT_REQUEST,
        payload: {clientId},
    }),
    requestPriceReportsSuccess: (
        priceReports: IPriceReport[]
    ) => ({
        type: PRICE_REPORT_REQUEST_SUCCESS,
        payload: {getPriceReports: priceReports},
    }),

    requestPriceReportFail: () => ({type: PRICE_REPORT_REQUEST_FAIL}),

    setClient: (client: string) => ({
        type: SET_CLIENT,
        payload: {client: client},
    }),

    changeSearchText: (searchText: string) => ({
        type: CHANGE_SEARCH_TEXT,
        payload: {searchText},
    }),

    requestPriceReportPdf: (reportId: string, locale: string) => ({
        type: PRICE_REPORT_PDF_REQUEST,
        payload: {reportId, locale},
    }),

    requestPriceReportPdfSuccess: () => ({
        type: PRICE_REPORT_PDF_REQUEST_SUCCESS,
    }),

    requestPriceReportPdfFail: () => ({
        type: PRICE_REPORT_PDF_REQUEST_FAIL,
    }),
}

export const selectors = {
    getPriceReports: (state: RootState) => state.priceReports.getPriceReports,
    getPriceReportsLoading: (state: RootState) => state.priceReports.getPriceReportsLoading,
    getPriceReportsError: (state: RootState) => state.priceReports.getPriceReportsError,
    getClient: (state: RootState) => state.priceReports.client,
    getSearchText: (state: RootState) => state.priceReports.searchText,
    getDownloadPdfLoading: (state: RootState) => state.priceReports.downloadPdfLoading,
    getDownloadPdfError: (state: RootState) => state.priceReports.downloadPdfError,
}


function* listUpdate(clientId: string) {
    const {data} = yield getPriceReportList(clientId)
    return {data}
}

export function* saga() {
    yield takeLatest(
        PRICE_REPORT_REQUEST,
        function* getPriceReportsSaga(action: ActionWithDefinePayload<{ clientId: string }>) {
            try {
                const {data} = yield listUpdate(action.payload.clientId)

                yield put(
                    actions.requestPriceReportsSuccess(
                        data.data
                    )
                )
            } catch (error) {
                console.error('Błąd pobierania raportów cenowych:', error)
                yield put(actions.requestPriceReportFail())
            }
        }
    )

    yield takeLatest(
        PRICE_REPORT_PDF_REQUEST,
        function* downloadPriceReportSaga(action: ActionWithDefinePayload<{ reportId: string, locale: string }>) {
            try {
                const {reportId, locale} = action.payload;
                const response: AxiosResponse<{
                    data: { pdf: string, fileName: string }
                }> = yield call(getPriceReportPdf, reportId, locale);

                const {pdf, fileName} = response.data.data;

                if (pdf) {
                    const blob = new Blob([new Uint8Array(atob(pdf).split("").map(c => c.charCodeAt(0)))], {type: 'application/pdf'});

                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const link = document.createElement('a');
                        link.href = reader.result as string;
                        link.download = fileName;
                        link.click();
                    };

                    reader.readAsDataURL(blob);

                }

                yield put(actions.requestPriceReportPdfSuccess());
            } catch (error) {
                console.error('Błąd pobierania PDF:', error);
                yield put(actions.requestPriceReportPdfFail());
            }
        })
}