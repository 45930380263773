import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AlertProvider from '../_metronic/layout/core/AlertContext'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import {TitleProvider} from "./TitleContext"

type Props = {
    basename: string
}

const App: React.FC<Props> = ({basename}) => {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <BrowserRouter basename={basename}>
                <I18nProvider>
                    <AlertProvider>
                        <LayoutProvider>
                            <TitleProvider>
                                <AuthInit>
                                    <Routes/>
                                </AuthInit>
                            </TitleProvider>
                        </LayoutProvider>
                    </AlertProvider>
                </I18nProvider>
            </BrowserRouter>
        </Suspense>
    )
}

export {App}
