/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Col} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useTitle} from "../../TitleContext";


const DashboardPage: React.FC = () => {
    const intl = useIntl()
    const { setTitle } = useTitle()
    setTitle(intl.formatMessage({id: 'PAGE_TITLE.MAIN_PAGE'}))

    return (
        <>
            <div className='row gy-5 g-xl-8'>
                <Col xxl={12}>Content</Col>
            </div>
        </>)
}

const DashboardWrapper: React.FC = () => {
    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <DashboardPage/>
        </>
    )
}

export {DashboardWrapper}
