import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const PRICE_REPORT_API = `${API_URL}/price-reports`
export const PRICE_REPORT_PDF_API = `${API_URL}/price-reports/pdf`

export async function getPriceReportList(
    clientId: string,
) {
    return await axios.get(
        `${PRICE_REPORT_API}?projectId=${clientId}`
    )
}
export async function getPriceReportPdf(reportId: string, locale: string) {
    return await axios.get(`${PRICE_REPORT_PDF_API}?reportId=${reportId}&lang=${locale}`)
}